<template>
    <v-container fluid>
        <v-row no-gutters>
            <v-col cols="12">
                <v-form ref="testForm" @submit.prevent="saveTest" class="custom-form">
                    <v-card :elevation="1" color="blue lighten-5">
                        <v-row no-gutters>
                            <v-col cols="12">
                                <v-toolbar color="#546E7A" dark :elevation="1" height="30px">
                                    <v-toolbar-title class="subtitle-2">Test Entry</v-toolbar-title>
                                </v-toolbar>
                                <v-card-text class="py-0 mt-1">
                                    <v-row dense justify="center">
                                        <v-col cols="4">
                                            <v-row no-gutters class="mb-1">
                                                <v-col cols="3">Test ID</v-col>
                                                <v-col cols="9">
                                                    <v-text-field
                                                        dense
                                                        outlined
                                                        hide-details
                                                        :value="$store.getters['test/newCode']"
                                                    ></v-text-field>
                                                </v-col>
                                            </v-row>
                                            <v-row no-gutters class="mb-1">
                                                <v-col cols="3">Space Man</v-col>
                                                <v-col cols="9">
                                                    <v-text-field
                                                        dense
                                                        outlined
                                                        hide-details
                                                        :rules="[v => !!v]"
                                                        v-model.trim="test.department"
                                                    ></v-text-field>
                                                </v-col>
                                            </v-row>
                                            <v-row no-gutters class="mb-1">
                                                <v-col cols="3">Test Name</v-col>
                                                <v-col cols="9">
                                                    <v-text-field
                                                        dense
                                                        outlined
                                                        hide-details
                                                        :rules="[v => !!v]"
                                                        v-model.trim="test.name"
                                                    ></v-text-field>
                                                </v-col>
                                            </v-row>
                                            <v-row no-gutters class="mb-1">
                                                <v-col cols="3">Ref. Value</v-col>
                                                <v-col cols="9">
                                                    <v-text-field
                                                        dense
                                                        outlined
                                                        hide-details
                                                        :rules="[v => !!v]"
                                                        v-model.trim="test.reference_values"
                                                    ></v-text-field>
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                        <v-col cols="4">
                                            <v-row no-gutters class="mb-1">
                                                <v-col cols="3" class="text-right pr-2">Room No.</v-col>
                                                <v-col cols="9">
                                                    <v-text-field
                                                        dense
                                                        outlined
                                                        hide-details
                                                        :rules="[v => !!v]"
                                                        v-model.trim="test.room_number"
                                                    ></v-text-field>
                                                </v-col>
                                            </v-row>
                                            <v-row no-gutters class="mb-1">
                                                <v-col cols="3" class="text-right pr-2">Price</v-col>
                                                <v-col cols="9">
                                                    <v-text-field
                                                        dense
                                                        outlined
                                                        hide-details
                                                        :rules="[v => !!v]"
                                                        v-model.number="test.price"
                                                    ></v-text-field>
                                                </v-col>
                                            </v-row>
                                            <v-row no-gutters class="mb-1">
                                                <v-col cols="3" class="text-right pr-2">Time</v-col>
                                                <v-col cols="3">
                                                    <v-text-field
                                                        dense
                                                        outlined
                                                        hide-details
                                                        placeholder="Day"
                                                        class="mr-1"
                                                        v-model.number="test.days_needed"
                                                    ></v-text-field>
                                                </v-col>
                                                <v-col cols="3">
                                                    <v-text-field
                                                        dense
                                                        outlined
                                                        hide-details
                                                        placeholder="Hour"
                                                        class="mr-1"
                                                        v-model.number="test.hours_needed"
                                                    ></v-text-field>
                                                </v-col>
                                                <v-col cols="3">
                                                    <v-text-field
                                                        dense
                                                        outlined
                                                        hide-details
                                                        placeholder="Minute"
                                                        v-model.number="test.minutes_needed"
                                                    ></v-text-field>
                                                </v-col>
                                            </v-row>
                                            <v-row no-gutters class="mb-1">
                                                <v-col cols="3" class="text-right pr-2">Comsi. (%)</v-col>
                                                <v-col cols="9">
                                                    <v-text-field
                                                        dense
                                                        outlined
                                                        hide-details
                                                        :rules="[v => !!v]"
                                                        v-model.number="test.commission"
                                                    ></v-text-field>
                                                </v-col>
                                            </v-row>
                                            <v-row no-gutters>
                                                <v-col cols="3"></v-col>
                                                <v-col cols="9">
                                                    <v-row no-gutters>
                                                         <v-col cols="6" class="pr-1">
                                                            <v-btn @click="resetForm" height="30px" dark block color="deep-orange">Clear</v-btn>
                                                        </v-col>
                                                        <v-col cols="6">
                                                            <v-btn type="submit" :loading="waitingForSave" height="30px" dark block color="light-blue darken-2">Save</v-btn>
                                                        </v-col>
                                                    </v-row>
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col md="12">
                                            <label>Investigation</label>
                                            <ckeditor v-model="test.investigation" :config="editorConfig"></ckeditor>
                                        </v-col>
                                  
                                    </v-row>
                                </v-card-text>
                            </v-col>
                        </v-row>
                    </v-card>
                </v-form>
            </v-col>
        </v-row>
        <v-row dense class="mt-1">
            <v-col cols="12" class="pb-0">
                <v-data-table
                    class="custom-data-table elevation-1"
                    dense
                    :headers="testHeaders"
                    :loading="$store.getters['test/loading']"
                    :items="$store.getters['test/tests']"
                    :search="searchTest"
                >
                    <template v-slot:top>
                        <v-toolbar dense color="white" :elevation="1" height="30px">
                            <v-toolbar-title class="subtitle-2">Test List</v-toolbar-title>
                            <v-divider class="mx-4" inset vertical></v-divider>
                            <v-form class="custom-form">
                                <v-text-field
                                    outlined
                                    dense
                                    hide-details
                                    placeholder="Search Test"
                                    append-icon="mdi-magnify"
                                    style="width:300px;"
                                    v-model="searchTest"
                                >
                                </v-text-field>
                            </v-form>
                        </v-toolbar>
                    </template>
                    <template v-slot:[`item.action`]="{ item }">
						<v-tooltip bottom>
							<template v-slot:activator="{ on }">
								<v-icon small @click="editTest(item)" color="primary" v-on="on">mdi-circle-edit-outline</v-icon>
							</template>
							<span>Edit</span>
						</v-tooltip>
						<v-tooltip bottom>
							<template v-slot:activator="{ on }">
								<v-icon small @click="openDeleteDialog(item.id)" color="error" v-on="on" >mdi-delete-circle-outline</v-icon>
							</template>
							<span>Delete</span>
						</v-tooltip>
					</template>
                </v-data-table>
            </v-col>
        </v-row>

        <delete-confirm ref="deleteDialog" @confirm="deleteTest"></delete-confirm>

    </v-container>
</template>

<script>
import DeleteConfirm from '@/components/Confirm'
import CKEditor from 'ckeditor4-vue'

export default {
    name: 'Test',
    components: {
        DeleteConfirm,
        ckeditor: CKEditor.component
    },
    data: () => ({
        editorConfig: {
            toolbar: [ ['Styles','Format','Bold','Italic', 'NumberedList', 'BulletedList', 'Table', 'Source'] ],
            height: 160
        },

        testHeaders: [
            { text: 'Test ID', value: 'code' },
            { text: 'Test Name', value: 'name' },
            { text: 'Room Number', value: 'room_number' },
            { text: 'Price', value: 'price' },
            { text: 'Commission', value: 'commission' },
            { text: 'Time', value: 'total_time_needed' },
            { text: 'Saved By', value: 'added_by.name' },
            { text: 'Action', value: 'action' },
        ],
        searchTest: '',
        test: {
            name: '',
            room_number: '',
            price: 0,
            commission: 0,
            days_needed: '',
            hours_needed: '',
            minutes_needed: '',
            department: '',
            investigation: '',
            reference_values: ''
        },
        waitingForSave: false,
        testEditId: null,
        testDeleteId: null,
    }),
    created() {
        this.$store.dispatch('test/getTestCode')
        this.$store.dispatch('test/getTests')
    },
    methods: {
        async saveTest() {
            if (!this.$refs.testForm.validate()) return

            this.waitingForSave = true

            if (this.testEditId == null) {
                let res = await this.$store.dispatch('test/saveTest', {
                    url: 'add-test',
                    data: this.test
                })
                if (res) this.resetForm()
            } else {
                let res = await this.$store.dispatch('test/saveTest', {
                    url: 'update-test',
                    data: {...this.test, id: this.testEditId}
                })
                if (res) this.resetForm()
            }

            this.waitingForSave = false
        },

        editTest(item) {
            this.testEditId = item.id
            Object.keys(this.test).map(k => this.test[k] = item[k])
        },

        openDeleteDialog(id) {
            this.testDeleteId = id
            this.$refs.deleteDialog.dialog = true
        },

        deleteTest() {
            this.$store.dispatch('test/deleteTest', {id: this.testDeleteId})
            this.$refs.deleteDialog.dialog = false
            this.testDeleteId = null
        },
        
        resetForm() {
            Object.keys(this.test).map(k => this.test[k] = '')
            this.test.price = 0
            this.test.commission = 0
            this.testEditId = null
            this.$refs.testForm.resetValidation()
        }
    }
}
</script>

<style lang="scss" scoped>
    .v-icon.v-icon {
       font-size: 18px !important;
       top: -3px !important;
    }
    .v-icon.v-icon[data-v-1f38b4e5] {
        font-size: 14px !important;
        top: 0px !important; 
    }
    .v-messages {
        flex: 1 1 auto;
        font-size: 12px;
        min-height: 0px !important;
        max-height: 0px!important;
        min-width: 1px;
        position: relative;
    }
</style>